<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('research_seed.application') + ' ' + $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
            <b-col lg="5" md="5" sm="12" xs="12">
                <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('research_seed.applicant_name')"
                label-for="name"
                >
                    <b-form-input
                        id="name"
                        v-model="search.name"
                        placeholder=""
                    ></b-form-input>
                </b-form-group>
            </b-col>
          <b-col lg="2" md="2" sm="12" xs="12">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('research_seed.application') + ' ' + $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="datas" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(applicationId)="data">
                      {{ $n(data.item.applicationId, { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(created_at)="data">
                      <span class="capitalize">{{ getDate(data.item.created_at) }}</span>
                    </template>
                    <template v-slot:cell(applicant_name)="data">
                      <span class="capitalize">{{ data.item.applicant_name }}</span>
                    </template>
                    <template v-slot:cell(applicant_name_bn)="data">
                      {{ data.item.applicant_name_bn }}
                    </template>
                    <template v-slot:cell(applicant_org_name)="data">
                      <span class="capitalize">{{ data.item.applicant_org_name || getOrgName(data.item.govt_org_id) }}</span>
                    </template>
                    <template v-slot:cell(applicant_org_name_bn)="data">
                      <span class="capitalize">{{ data.item.applicant_org_name_bn || getOrgName(data.item.govt_org_id) }}</span>
                    </template>
                    <template v-slot:cell(seed_name)="data">
                      <span class="capitalize">{{ getSeedName(data.item.seed_name_id) }}</span>
                    </template>
                    <template v-slot:cell(seed_variety)="data">
                      <span class="capitalize">{{ getSeedVariety(data.item.seed_variety_id) }}</span>
                    </template>
                    <template v-slot:cell(seed_class)="data">
                      <span class="capitalize">{{ getSeedClass(data.item.seed_class_id) }}</span>
                    </template>
                    <template v-slot:cell(application_status)="data">
                      <span class="badge badge-primary"> {{ data.item.approved_rejec_status === 2 ? $t('research_seed.pending') :  data.item.approved_rejec_status === 0 ? $t('research_seed.approved') : data.item.approved_rejec_status === 3 ? $t('globalTrans.forward') + ' ' +$t('globalTrans.to_nothi') : $t('research_seed.rejected') }} </span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-badge class="cursor" v-if="data.item.approved_rejec_status == 2" :title="$t('research_seed.approve')" v-b-modal.modal-4 variant =" iq-bg-success mr-1 mb-1" size="sm" @click="approve(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-badge>
                      <b-badge class="cursor" v-if="data.item.approved_rejec_status == 2" :title="$t('research_seed.reject')" v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="reject(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-badge>
                      <!-- <b-badge class="cursor" title="View" v-b-modal.modal-5 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="view(data.item)"><i class="ri-eye-line m-0 "></i> View </b-badge> -->
                      <b-badge class="cursor" :title="$t('research_seed.inspection_report')" v-b-modal.modal-6 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="viewReport(data.item)"><i class="fas fa-campground"></i></b-badge>
                      <!-- <b-badge class="cursor" title="Remarks" v-b-modal.modal-5 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="viewRemarks(data.item)"><i class="fas fa-bars"></i> Remarks </b-badge> -->
                      <b-badge class="cursor" :title="$t('research_seed.view_details')" v-b-modal.modal-7 variant=" iq-bg-success mr-1" size="sm" @click="viewApp(data.item)"><i class="ri-eye-line m-0 "><span class="ml-2"></span></i></b-badge>
                      <b-button v-if="$store.state.Auth.authUser.ssoLogin && data.item.approved_rejec_status === 2" style="font-size: 12px !important; margin-top:10px" variant="btn btn-xs btn-success" size="sm" @click="finalSave(data.item)" ><i class="fa fa-forward"></i> {{$t('globalTrans.to_nothi')}}</b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" :size="action==='approve' ? 'md' : 'xl' " :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <FormV :action="action" :id="editItemId" :key="editItemId"/>
    </b-modal>
    <!-- <b-modal id="modal-5" size="xl" :title="applicationDetails" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :application="application"/>
    </b-modal> -->
    <b-modal id="modal-6" size="xl" :title="$t('research_seed.approval_form') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <InspectionReport :id="editItemId"/>
    </b-modal>
    <b-modal ref="myComponent" id="modal-7" size="xl" :title="this.$t('research_seed.details_title')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <AllDetails :id="application" :key="application" />
    </b-modal>
    <div style ="display:none" id="myP">
      <NothiDetails @isCheckApiCall="checkApiCall($event)" v-if="isShowModel" :id="application" :key="application" />
    </div>
  </b-container>
</template>

<script>
import FormV from './ApprovalRejectionForm.vue'
import InspectionReport from './InspectionReportDetails.vue'
import AllDetails from '../application-verification/Details'
import NothiDetails from '../application-verification/NothiDetails'
// import Details from '../../../../../external-user-service/agri-research/pages/field-application-list/Application.vue'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { getListForFilter } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import researchTestingHelper from '@/mixins/agri-research-helpers'
import { mapGetters } from 'vuex'
import { core } from '@/config/pluginInit'
import moment from 'moment'
import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList, researchTestingHelper],
    components: {
        FormV,
        InspectionReport,
        AllDetails,
        NothiDetails
    },
    data () {
        return {
            remarks: false,
            datas: [],
            search: {
                name: '',
                service_info_id: '',
                org_id: 0,
                office_id: 0
            },
            application: {},
            action: '',
            sentNothi: 0,
            isShowModel: false,
            applicationDetails: this.$t('research_seed.approval_form')
        }
    },
    created () {
    if (this.authUser.role_id === 1) {
    } else if (this.authUser.is_org_admin === 1) {
      this.search.org_id = this.authUser.org_id
    } else if (this.authUser.is_admin === true) {
      this.search.org_id = this.authUser.org_id
      this.search.office_id = this.authUser.office_id
    }
    },
    computed: {
        ...mapGetters({
            authUser: 'Auth/authUser'
        }),
        formTitle () {
            return (this.action === 'approve') ? this.$t('research_seed.approval_form') : this.$t('research_seed.rejection_form')
        },
        columns () {
            const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
              { label: this.$t('research_seed.application_id'), class: 'text-left' },
              { label: this.$t('research_seed.application_date'), class: 'text-left' },
              { label: this.$t('research_seed.applicant_name'), class: 'text-left' },
              { label: this.$t('globalTrans.organization'), class: 'text-left' },
              { label: this.$t('research_seed.seed_name'), class: 'text-left' },
              { label: this.$t('research_seed.seed_variety_name'), class: 'text-left' },
              { label: this.$t('research_seed.seed_class_name'), class: 'text-left' },
              { label: this.$t('globalTrans.status'), class: 'text-center' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'applicationId' },
                { key: 'created_at' },
                { key: 'applicant_name_bn' },
                { key: 'applicant_org_name_bn' },
                { key: 'seed_name' },
                { key: 'seed_variety' },
                { key: 'seed_class' },
                { key: 'application_status' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'applicationId' },
                { key: 'created_at' },
                { key: 'applicant_name' },
                { key: 'applicant_org_name' },
                { key: 'seed_name' },
                { key: 'seed_variety' },
                { key: 'seed_class' },
                { key: 'application_status' },
                { key: 'action' }
                ]
            }
            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    mounted () {
      core.index()
      // if (this.authUser.role_id === 0) {
      //     this.search = Object.assign({}, this.search)
      // }
      this.loadData()
    },
    methods: {
      checkApiCall (isCall) {
        if (isCall) {
          this.loadData()
        }
      },
      async finalSave (item) {
        this.isShowModel = true
        this.sentNothi = 1
        this.application = parseInt(item.general_info_id)
      },
      async toggleStatusCustom2 (baseUrl, uri, item) {
        window.vm.$swal({
          title: window.vm.$t('elearning_venue.are_you_sure_forward_to_nothi'),
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.changeStatusCustom(baseUrl, uri, item)
          }
        })
      },
      async changeStatusCustom (baseUrl, uri, item) {
        Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.postData(baseUrl, uri, item).then(response => {
          if (response.success) {
            window.vm.$toast.success({
              title: 'Success',
              message: 'Data Updated Successfully',
              color: '#D6E09B'
            })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
      },
      getOrgName (id) {
        if (id) {
          const tmpOrg = this.$store.state.commonObj.organizationProfileList.find(newItem => newItem.value === parseInt(id))
          if (tmpOrg) {
            if (this.$i18n.locale === 'en') {
              return tmpOrg.text_en
            } else {
              return tmpOrg.text_bn
            }
          } else {
            return ''
          }
        }
      },
      getDate (date) {
        return moment(date).format('L')
      },
      approve (item) {
          this.action = 'approve'
          this.editItemId = item.approval_rejection_id
      },
      reject (item) {
          this.action = 'reject'
          this.editItemId = item.approval_rejection_id
      },
      viewReport (item) {
          this.editItemId = item.report_id
      },
      async searchData () {
          this.loadData()
      },
      loadData () {
          this.$store.dispatch('mutateCommonProperties', { loading: true })
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(agriResearchServiceBaseUrl, getListForFilter, params).then(response => {
              if (response.success) {
                  this.$store.dispatch('setList', response.data.data)
                  this.paginationData(response.data)
                  const allData = this.$store.state.list
                  this.datas = JSON.parse(JSON.stringify(allData))
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getSeedName (id) {
        const seed = this.$store.state.AgriResearch.commonObj.seedNameList.find(item => item.value === parseInt(id))
        if (this.$i18n.locale === 'en') {
          return seed.text_en
        } else {
          return seed.text_bn
        }
      },
      getSeedVariety (id) {
          const seed = this.$store.state.AgriResearch.commonObj.seedVarietyList.find(item => item.value === parseInt(id))
          return seed !== undefined ? this.$i18n.locale === 'en' ? seed.text_en : seed.text_bn : ''
      },
      getSeedClass (id) {
        if (id > 0) {
          const seed = this.$store.state.AgriResearch.commonObj.seedClassNameList.find(item => item.value === parseInt(id))
          if (this.$i18n.locale === 'en') {
            return seed.text_en
          } else {
            return seed.text_bn
          }
        }
      },
      view (item) {
          this.remarks = false
          this.application = item
      },
      viewRemarks (item) {
          this.remarks = true
          this.application = item.id
      },
      dataList (data) {
          const listData = data
          return listData
      },
      async viewApp (item) {
        this.isShowModel = false
        this.application = parseInt(item.general_info_id)
      }
    }
}
</script>
<style scoped>
  .cursor{
    cursor: pointer;
  }
</style>
