<template>
<b-row>
    <b-col lg="12" sm="12">
    <b-overlay :show="loadingState">
        <iq-card>
            <template v-slot:headerTitle>
                <div class="text-center">
                    <h5 class="text-dark"> {{ $t('research_seed.inspection_report_details') }}</h5>
                </div>
            </template>
            <template v-slot:body>
                <b-row style="">
                    <b-col md="12" lg="12">
                        <b-table-simple striped bordered small>
                            <b-tr>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.inspection_date')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{data.inspection_date | dateFormat }}
                                        </p>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.representative_present')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{ data.representative_present === '0' ? ($i18n.locale === 'en' ? 'Yes' : 'হ্যাঁ') : ($i18n.locale == 'en' ? 'No' : 'না') }}
                                        </p>
                                    </div>
                                </b-td>
                            </b-tr>
                        </b-table-simple>
                    </b-col>
                    <!-- <b-col md="6" lg="6">
                        <ValidationProvider name="Inspection Date" vid="inspection_date" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="inspection_date"
                            slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('research_seed.inspection_date')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input

                                class="date-picker"
                                id="inspection_date"
                                v-model="info.inspection_date"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col md="6" lg="6">
                        <ValidationProvider name="Is it Final ?" vid="final_report" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="final_report"
                            slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('research_seed.is_final_report')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select

                            id="final_report"
                            :options="isFinal"
                            v-model="info.final_report"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col md="6" lg="6">
                        <ValidationProvider name="Is Representative Present ?" vid="representative_present" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="representative_present"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                                {{$t('research_seed.representative_present')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select

                                id="representative_present"
                                :options="isFinal"
                                v-model="info.representative_present"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col> -->
                </b-row>
            </template>
        </iq-card>
        <template v-if="level_one">
            <iq-card>
            <template v-slot:headerTitle>
                <div class="text-center">
                    <h5 class="text-dark"> {{ $t('research_seed.verification_seed_source') }} ({{ $t('research_seed.first_inspec')}})</h5>
                </div>
            </template>
            <template v-slot:body>
                <b-row style="">
                    <b-col md="12" lg="12">
                        <b-table-simple striped bordered small>
                            <b-tr>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.amount_of_land')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{ $n(level_one.amount_of_land) }}
                                        </p>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div v-if="level_one.hectors_unit_id" class="d-flex">
                                        <b>
                                            {{$t('certify_form.unit_measurement')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{getUnit(level_one.hectors_unit_id)}}
                                        </p>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.hectors_of_field')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{ $n(level_one.hectors_of_field) }}
                                        </p>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div v-if="level_one.ensuring_seed_quality" class="d-flex">
                                        <b>
                                            {{$t('research_seed.ensuring_seed_quality')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{ getYesNo(level_one.ensuring_seed_quality) }}
                                        </p>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.other_file')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            <a :href="level_one.other_file" target="_blank" rel="noopener noreferrer">View</a>
                                        </p>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div  class="d-flex">
                                        <b>
                                            {{$t('research_seed.seed_production_file')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            <a :href="level_one.seed_production_file" target="_blank" rel="noopener noreferrer">View</a>
                                        </p>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.importing_seed_file')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            <a :href="level_one.importing_seed_file" target="_blank" rel="noopener noreferrer">View</a>
                                        </p>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div  class="d-flex">
                                        <b>
                                            {{$t('research_seed.purchase_seed_file')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            <a :href="level_one.purchase_seed_file" target="_blank" rel="noopener noreferrer">View</a>
                                        </p>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.separation_distance')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{level_one.separation_distance}}
                                        </p>
                                    </div>
                                </b-td>
                                <b-td v-if="level_one.separation_unit_id">
                                    <div  class="d-flex">
                                        <b>
                                            {{$t('certify_form.unit_measurement')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{getUnit(level_one.separation_unit_id)}}
                                        </p>
                                    </div>
                                </b-td>
                            </b-tr>
                        </b-table-simple>
                        <b-row>
                            <b-col lg="10">
                                <template v-slot:label>
                                {{$t('certify_form.plot_location')}} <span class="text-danger">*</span>
                                </template>
                                <iframe width="100%" height="250" frameborder="0" scrolling="yes" marginheight="0" marginwidth="0" :src="'https://www.google.com/maps/embed?origin=mfe&pb=!1m3!2m1!1s' + level_one.latitude + ',' + level_one.longitude + '!6i15'"></iframe>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </template>
            </iq-card>
            <iq-card>
                <template v-slot:headerTitle>
                    <div class="text-center">
                        <h5 class="text-dark"> {{ $t('research_seed.inspection_officer') }}</h5>
                    </div>
                </template>
                <template v-slot:body>
                    <b-row style="">
                        <b-col md="12">
                            <b-table-simple striped bordered small>
                                <b-tr v-for="(item,index) in level_one.first_officer" :key="index">
                                    <b-td v-if="item.organization">
                                        <div class="d-flex">
                                            <b>
                                                {{$t('globalTrans.organization')}} :
                                            </b>
                                            <p class="text-dark ml-4">
                                                {{getOrg(item.organization)}}
                                            </p>
                                        </div>
                                    </b-td>
                                    <b-td>
                                        <div v-if="item.employee_designation" class="d-flex">
                                            <b>
                                                {{$t('globalTrans.designation')}} :
                                            </b>
                                            <p class="text-dark ml-4">
                                                {{getDesig(item.employee_designation)}}
                                            </p>
                                        </div>
                                    </b-td>
                                    <b-td>
                                        <div v-if="item.employee_name && employeeList.length>0" class="d-flex">
                                            <b>
                                                {{$t('research_seed.employee')}} :
                                            </b>
                                            <p class="text-dark ml-4">
                                                {{getEmp(item.employee_name)}}
                                            </p>
                                        </div>
                                    </b-td>
                                    <b-td>
                                        <div class="d-flex">
                                            <b>
                                                {{$t('research_seed.remarks')}} :
                                            </b>
                                            <p class="text-dark ml-4">
                                                {{ $i18n.locale === 'en' ? item.remarks : item.remarks_bn}}
                                            </p>
                                        </div>
                                    </b-td>
                                </b-tr>
                            </b-table-simple>
                        </b-col>
                    </b-row>
                </template>
            </iq-card>
        </template>
        <template v-if="level_two">
            <iq-card>
            <template v-slot:headerTitle>
                <div class="text-center">
                    <h5 class="text-dark"> {{ $t('research_seed.verification_seed_source') }} ({{ $t('research_seed.second_inspec')}})</h5>
                </div>
            </template>
            <template v-slot:body>
                <b-row style="">
                    <b-col md="12" lg="12">
                        <b-table-simple striped bordered small>
                            <b-tr>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.separation_distance')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{level_two.second_separation_distance}}
                                        </p>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div v-if="level_two.second_separation_unit_id" class="d-flex">
                                        <b>
                                            {{$t('certify_form.unit_measurement')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{getUnit(level_two.second_separation_unit_id)}}
                                        </p>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.mixture_varieties')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{ $i18n.locale === 'bn' ? level_two.second_mixture_varieties_bn : level_two.second_mixture_varieties }}
                                        </p>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.other_variety_type')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{ $i18n.locale === 'bn' ? level_two.second_variety_type_bn : level_two.second_variety_type }}
                                        </p>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.offensive_weeds')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{ $i18n.locale === 'bn' ? level_two.second_offensive_weeds_bn : level_two.second_offensive_weeds }}
                                        </p>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.diseased_trees')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{ $i18n.locale === 'bn' ? level_two.second_diseased_trees_bn : level_two.second_diseased_trees }}
                                        </p>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.crop_condition')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{ $i18n.locale === 'bn' ? level_two.second_crop_condition_bn : level_two.second_crop_condition }}
                                        </p>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.tree_counts')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{ level_two.second_tree_counts }}
                                        </p>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.second_tree_sheaves')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{level_two.second_tree_sheaves}}
                                        </p>
                                    </div>
                                </b-td>
                            </b-tr>
                        </b-table-simple>
                    </b-col>
                </b-row>
            </template>
            </iq-card>
            <iq-card>
                <template v-slot:headerTitle>
                    <div class="text-center">
                        <h5 class="text-dark"> {{ $t('research_seed.inspection_officer') }}</h5>
                    </div>
                </template>
                <template v-slot:body>
                    <b-row style="">
                        <b-col md="12">
                            <b-table-simple striped bordered small>
                                <b-tr v-for="(item,index) in level_two.second_officer" :key="index">
                                    <b-td v-if="item.organization">
                                        <div class="d-flex">
                                            <b>
                                                {{$t('globalTrans.organization')}} :
                                            </b>
                                            <p class="text-dark ml-4">
                                                {{getOrg(item.organization)}}
                                            </p>
                                        </div>
                                    </b-td>
                                    <b-td>
                                        <div v-if="item.employee_designation" class="d-flex">
                                            <b>
                                                {{$t('globalTrans.designation')}} :
                                            </b>
                                            <p class="text-dark ml-4">
                                                {{getDesig(item.employee_designation)}}
                                            </p>
                                        </div>
                                    </b-td>
                                    <b-td>
                                        <div v-if="item.employee_name && employeeList.length>0" class="d-flex">
                                            <b>
                                                {{$t('research_seed.employee')}} :
                                            </b>
                                            <p class="text-dark ml-4">
                                                {{getEmp(item.employee_name)}}
                                            </p>
                                        </div>
                                    </b-td>
                                    <b-td>
                                        <div class="d-flex">
                                            <b>
                                                {{$t('research_seed.remarks')}} :
                                            </b>
                                            <p class="text-dark ml-4">
                                                {{ $i18n.locale === 'en' ? item.remarks : item.remarks_bn}}
                                            </p>
                                        </div>
                                    </b-td>
                                </b-tr>
                            </b-table-simple>
                        </b-col>
                    </b-row>
                </template>
            </iq-card>
        </template>
        <template v-if="level_three">
            <iq-card>
            <template v-slot:headerTitle>
                <div class="text-center">
                    <h5 class="text-dark"> {{ $t('research_seed.verification_seed_source') }} ({{ $t('research_seed.third_inspec')}})</h5>
                </div>
            </template>
            <template v-slot:body>
                <b-row style="">
                    <b-col md="12" lg="12">
                        <b-table-simple striped bordered small>
                            <b-tr>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.mixture_varieties')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{ $i18n.locale === 'bn' ? level_three.third_mixture_varieties_bn : level_three.third_mixture_varieties }}
                                        </p>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.other_variety_type')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{ $i18n.locale === 'bn' ? level_three.third_variety_type_bn : level_three.third_variety_type }}
                                        </p>
                                    </div>
                                </b-td>
                                <!-- <b-td>
                                    <div v-if="level_three.ensuring_seed_quality" class="d-flex">
                                        <b>
                                            {{$t('research_seed.ensuring_seed_quality')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{getYesNo(level_three.ensuring_seed_quality)}}
                                        </p>
                                    </div>
                                </b-td> -->
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.offensive_weeds')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{ $i18n.locale === 'bn' ? level_three.third_offensive_weeds_bn : level_three.third_offensive_weeds }}
                                        </p>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.diseased_trees')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{ $i18n.locale === 'bn' ? level_three.third_diseased_trees_bn : level_three.third_diseased_trees }}
                                        </p>
                                    </div>
                                </b-td>
                                <!-- <b-td>
                                    <div  class="d-flex">
                                        <b>
                                            {{$t('research_seed.seed_production_file')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            <a :href="level_three.seed_production_file" target="_blank" rel="noopener noreferrer">View</a>
                                        </p>
                                    </div>
                                </b-td> -->
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.crop_condition')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{ $i18n.locale === 'bn' ? level_three.third_crop_condition_bn : level_three.third_crop_condition }}
                                        </p>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.tree_counts')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{ level_three.third_tree_counts }}
                                        </p>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.amount_of_land_acquired')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{level_three.third_land_acquired}}
                                        </p>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div v-if="level_three.third_land_acquired_unit_id" class="d-flex">
                                        <b>
                                            {{$t('certify_form.unit_measurement')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{getUnit(level_three.third_land_acquired_unit_id)}}
                                        </p>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.amount_of_cancelled_land')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{level_three.third_land_cancelled}}
                                        </p>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div v-if="level_three.third_land_cancelled_unit_id" class="d-flex">
                                        <b>
                                            {{$t('certify_form.unit_measurement')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{getUnit(level_three.third_land_cancelled_unit_id)}}
                                        </p>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.possible_seed_yield')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{level_three.third_seed_yield}}
                                        </p>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div v-if="level_three.third_seed_yield_unit_id" class="d-flex">
                                        <b>
                                            {{$t('certify_form.unit_measurement')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            {{getUnit(level_three.third_seed_yield_unit_id)}}
                                        </p>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.inspection_report')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            <a :href="level_three.third_inspection_report" target="_blank" rel="noopener noreferrer">View</a>
                                        </p>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div class="d-flex">
                                        <b>
                                            {{$t('research_seed.shortcut_to_application_form')}} :
                                        </b>
                                        <p class="text-dark ml-4">
                                            <a :href="level_three.third_shortcut_hyperlink" target="_blank" rel="noopener noreferrer">View</a>
                                        </p>
                                    </div>
                                </b-td>
                            </b-tr>
                        </b-table-simple>
                    </b-col>
                </b-row>
            </template>
            </iq-card>
            <iq-card>
                <template v-slot:headerTitle>
                    <div class="text-center">
                        <h5 class="text-dark"> {{ $t('research_seed.inspection_officer') }}</h5>
                    </div>
                </template>
                <template v-slot:body>
                    <b-row style="">
                        <b-col md="12">
                            <b-table-simple striped bordered small>
                                <b-tr v-for="(item,index) in level_three.third_officer" :key="index">
                                    <b-td v-if="item.organization">
                                        <div class="d-flex">
                                            <b>
                                                {{$t('globalTrans.organization')}} :
                                            </b>
                                            <p class="text-dark ml-4">
                                                {{getOrg(item.organization)}}
                                            </p>
                                        </div>
                                    </b-td>
                                    <b-td>
                                        <div v-if="item.employee_designation" class="d-flex">
                                            <b>
                                                {{$t('globalTrans.designation')}} :
                                            </b>
                                            <p class="text-dark ml-4">
                                                {{getDesig(item.employee_designation)}}
                                            </p>
                                        </div>
                                    </b-td>
                                    <b-td>
                                        <div v-if="item.employee_name && employeeList.length>0" class="d-flex">
                                            <b>
                                                {{$t('research_seed.employee')}} :
                                            </b>
                                            <p class="text-dark ml-4">
                                                {{getEmp(item.employee_name)}}
                                            </p>
                                        </div>
                                    </b-td>
                                    <b-td>
                                        <div class="d-flex">
                                            <b>
                                                {{$t('research_seed.remarks')}} :
                                            </b>
                                            <p class="text-dark ml-4">
                                                {{ $i18n.locale === 'en' ? item.remarks : item.remarks_bn}}
                                            </p>
                                        </div>
                                    </b-td>
                                </b-tr>
                            </b-table-simple>
                        </b-col>
                    </b-row>
                </template>
            </iq-card>
        </template>
    </b-overlay>
    </b-col>
</b-row>
</template>

<script>
import RestApi, { agriResearchServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { inspectionReportGet, getUserList } from '../../../api/routes'
import researchTestingModal from '@/mixins/research-testing-modal'
import researchTestingHelper from '@/mixins/agri-research-helpers'
export default {
    props: ['id'],
    mixins: [researchTestingModal, researchTestingHelper],
    data () {
        return {
            isFinal: [
                {
                    value: 0,
                    text: this.$i18n.locale === 'en' ? 'Yes' : 'হ্যাঁ'
                },
                {
                    value: 1,
                    text: this.$i18n.locale === 'en' ? 'No' : 'না'
                }
            ],
            level_one: {},
            level_two: {},
            level_three: {},
            data: {},
            employeeList: [],
            orgTypeList: [
                { value: 1, text: this.$t('certify_form.govt') },
                { value: 2, text: this.$t('certify_form.private') }
            ]
        }
    },
    created () {
        if (this.id) {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            this.getReport()
        }
    },
    computed: {
        designationList: function () {
            const list = this.$store.state.commonObj.designationList.filter(e => e.org_id === 14)
            return list.map((item) => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        }
    },
    methods: {
        getDesig (id) {
            return this.designationList.find(e => e.value === parseInt(id)).text
        },
        getEmp (id) {
                return this.employeeList.find(e => e.value === parseInt(id)).text
        },
        async getUserList (orgId, desigId) {
            const params = { org_id: orgId, designation_id: desigId }
           const result = await RestApi.getData(authServiceBaseUrl, getUserList, params)
            if (result.success) {
                result.data.map(e => {
                    const user = {
                        value: e.value, text: this.$i18n.locale === 'en' ? e.text_en : e.text_bn
                    }
                    const flag = this.employeeList.find(u => u.value === user.value)
                    if (!flag) {
                        this.employeeList.push(user)
                    }
                })
            }
        },
        async getReport () {
            const result = await RestApi.getData(agriResearchServiceBaseUrl, `${inspectionReportGet}/${this.id}`)
            if (result.success) {
                this.data = result.data
                this.level_one = result.data.first_inspection
                if (this.level_one) {
                    this.level_one.first_officer.forEach(el => {
                        this.getUserList(el.organization, el.employee_designation)
                    })
                }
                this.level_two = result.data.second_inspection
                if (this.level_two) {
                    this.level_two.second_officer.forEach(el => {
                        this.getUserList(el.organization, el.employee_designation)
                    })
                }
                this.level_three = result.data.third_inspection
                if (this.level_three) {
                    this.level_three.third_officer.forEach(el => {
                        this.getUserList(el.organization, el.employee_designation)
                    })
                }
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false })
        }
    }
}
</script>

<style>

</style>
