<template>
  <b-col md="12" lg="12">
    <b-overlay :show="loadingState">
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                <b-row v-if="action==='approve'">
                    <b-col md="12" lg="12">
                        <ValidationProvider name="Remarks" vid="remark" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="remark_en"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                    {{$t('globalTrans.remarks')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-textarea
                                    id="remark_en"
                                    v-model="info.remark"
                                    :state="errors[0] ? false : (valid ? true : null)">
                                </b-form-textarea>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>
                <b-row v-else>
                    <b-col md="6" lg="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="dealer_registration_number"
                        >
                            <template v-slot:label>
                                {{$t('certify_form.dealer_registration_number')}}
                            </template>
                            <b-form-input
                                disabled
                                type="number"
                                id="dealer_registration_number"
                                v-model="info.registration_number"
                            >
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <!-- <b-col md="6" lg="6">
                        <b-form-group class="row" label-cols-sm="6" label-for="plot_location">
                            <template v-slot:label>
                            {{$t('certify_form.plot_location')}} <span class="text-danger">*</span>
                            </template>
                            <iframe width="100%" height="250" frameborder="0" scrolling="yes" marginheight="0" marginwidth="0" :src="'https://www.google.com/maps/embed?origin=mfe&pb=!1m3!2m1!1s' + info.latitude + ',' + info.longitude + '!6i15'"></iframe>
                        </b-form-group>
                    </b-col> -->
                </b-row>
                <iq-card v-if="action==='reject'">
                    <template v-slot:headerTitle>
                        <div class="text-center">
                            <h5 class="text-dark"> {{ $t('research_seed.inspection_report_detals') }}</h5>
                        </div>
                    </template>
                    <template v-slot:body>
                        <b-row style="">
                            <b-col md="6" lg="6">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="separation_distance"
                                >
                                    <template v-slot:label>
                                        {{$t('research_seed.separation_distance')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        type="number"
                                        disabled
                                        id="separation_distance"
                                        v-model="info.separation_distance"
                                    >
                                    </b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="6" lg="6">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="mixture_varieties"
                                >
                                    <template v-slot:label>
                                        {{$t('research_seed.mixture_varieties')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        disabled
                                        id="mixture_varieties"
                                        v-model="info.other_varieties"
                                    >
                                    </b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="6" lg="6">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="other_variety_type"
                                >
                                    <template v-slot:label>
                                        {{$t('research_seed.other_variety_type')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        disabled
                                        id="other_variety_type"
                                        v-model="info.other_varieties"
                                    >
                                    </b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="6" lg="6">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="offensive_weeds"
                                >
                                    <template v-slot:label>
                                        {{$t('research_seed.offensive_weeds')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        disabled
                                        id="offensive_weeds"
                                        v-model="info.unwanted_weeds"
                                    >
                                    </b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="6" lg="6">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="diseased_trees_en"
                                >
                                    <template v-slot:label>
                                        {{$t('research_seed.seed_brone_diseases')}}
                                    </template>
                                    <b-form-input
                                        disabled
                                        id="diseased_trees_en"
                                        v-model="info.borne_diseases"
                                    >
                                    </b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="6" lg="6">
                                <ValidationProvider name="Remarks" vid="remark" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="remark_en"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                            {{$t('globalTrans.remarks')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-textarea
                                            id="remark_en"
                                            v-model="info.remark"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                        </b-form-textarea>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
                <div class="row">
                <div class="col-sm-3"></div>
                <div class="col text-right">
                    <b-button :disabled="final" type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <b-button type="button" variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                </div>
                </div>
            </b-form>
        </ValidationObserver>
    </b-overlay>
  </b-col>
</template>

<script>
import { core } from '../../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '../../../../../../config/api_config'
import { storeRemark, getRejected, getApproved } from '../../../api/routes'
import researchTestingModal from '@/mixins/research-testing-modal'
import researchTestingHelper from '@/mixins/agri-research-helpers'
import flatpickr from 'flatpickr'
export default {
    props: ['id', 'action'],
    data () {
        return {
            final: false,
            saveBtnName: this.$t('globalTrans.save'),
            employeeList: [],
            data: {},
            info: {
                approved_rejec_status: 0,
                remark: '',
                remark_bn: ''
            }
        }
    },
    computed: {
        unitList: function () {
            const units = this.$store.state.AgriResearch.commonObj.qualityUnitList.filter(item => item.status === 0)
            return units.map(item => {
                    return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
                })
        },
        orgList: function () {
            const objectData = this.$store.state.commonObj.organizationProfileList.filter(el => el.status === 0)
            return objectData.map(org => {
                    return { value: org.value, text: this.$i18n.locale === 'en' ? org.text_en : org.text_bn }
                })
        },
        designationList: function () {
            const list = this.$store.state.commonObj.designationList.filter(e => e.org_id === 14)
            return list.map((item) => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        }
    },
    components: {
        ValidationObserver,
        ValidationProvider
    },
    mixins: [researchTestingModal, researchTestingHelper],
    async created () {
        const tmp = await this.getData()
        if (tmp.success) {
            this.info = { ...tmp.data, ...this.info }
        }
    },
    mounted () {
        flatpickr('.date-picker', {})
        core.index()
    },
    methods: {
        async getData () {
        if (this.action === 'reject') {
                this.info.approved_rejec_status = 1
                const tmpData = await RestApi.getData(agriResearchServiceBaseUrl, `${getRejected}/${this.id}`)
                return JSON.parse(JSON.stringify(tmpData))
            } else {
                this.info.approved_rejec_status = 0
                const tmpData = await RestApi.getData(agriResearchServiceBaseUrl, `${getApproved}/${this.id}`)
                return JSON.parse(JSON.stringify(tmpData))
            }
        },
        async saveData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }
            result = await RestApi.postData(agriResearchServiceBaseUrl, storeRemark, this.info)
            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            if (result.success) {
                // const notification = result.notification
                // this.$socket.emit('send-notification', notification)
                loadingState.listReload = false
                this.$toast.success({
                    title: 'Success',
                    message: this.id ? 'Data updated successfully' : 'Data saved successfully',
                    color: '#D6E09B'
                })
                this.$bvModal.hide('modal-4')
                const notification = result.notification
                this.$socket.emit('send-notification', notification)
            } else {
                this.$refs.form.setErrors(result.errors)
                this.$toast.error({
                    title: 'Error',
                    message: 'Operation failed! Please, try again.'
                })
            }
        }
    }
}
</script>

<style>

</style>
